var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$permissionAbility(_vm.SALARY_PAYSLIP_CREATE, _vm.permissions) ? _c('b-card', {
    attrs: {
      "title": "Generate Payslip"
    }
  }, [_c('validation-observer', {
    ref: "generateAllAttendanceValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.generateAllReport($event);
      }
    }
  }, [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "empId"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "User",
      "vid": "empId"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "mb-1 custom-font",
          attrs: {
            "id": "empId",
            "name": "empId",
            "options": _vm.userIdOption,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "placeholder": "Employee"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectEmpId,
            callback: function callback($$v) {
              _vm.selectEmpId = $$v;
            },
            expression: "selectEmpId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 104679384)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterDepartmentIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Department"
    },
    on: {
      "input": _vm.changeDepartment
    },
    model: {
      value: _vm.selectDepartmentId,
      callback: function callback($$v) {
        _vm.selectDepartmentId = $$v;
      },
      expression: "selectDepartmentId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label-for": "payslip_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "payslip_date",
      "vid": "payslip_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('date-picker', {
          attrs: {
            "type": "month",
            "placeholder": "YYYY-MM",
            "editable": false
          },
          on: {
            "change": function change($event) {
              return _vm.changePayslipDate(_vm.payslipDate);
            }
          },
          model: {
            value: _vm.payslipDate,
            callback: function callback($$v) {
              _vm.payslipDate = $$v;
            },
            expression: "payslipDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1621545366)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center justify-content-around"
  }, [_vm.isReportGenerating ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 ml-1",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Report Generating .. ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 ml-1",
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.payslipDateInput ? false : true
    }
  }, [_vm._v(" Generate Payslip ")])]], 2)])])], 1)], 1)])], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "col",
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "searchTerm"
    }
  }, [_c('b-form-input', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "placeholder": "Search By User Name ...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "col",
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "searchTerm"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Enter Date Range",
      "config": {
        mode: 'range',
        defaultDate: [this.filterStartDate, this.filterEndDate]
      }
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "title": "Export",
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label-for": "payslip_date"
    }
  }, [_c('date-picker', {
    attrs: {
      "type": "month",
      "placeholder": "YYYY-MM",
      "editable": false
    },
    model: {
      value: _vm.payslipDateExcel,
      callback: function callback($$v) {
        _vm.payslipDateExcel = $$v;
      },
      expression: "payslipDateExcel"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center justify-content-around"
  }, [_vm.isExcelGenerating ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 ml-1",
    attrs: {
      "variant": "success",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Excel Generating .. ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 ml-1",
    attrs: {
      "variant": "success",
      "disabled": _vm.payslipDateExcel ? false : true
    },
    on: {
      "click": function click($event) {
        return _vm.callGenerateAllExcel(_vm.payslipDateExcel);
      }
    }
  }, [_vm._v(" Excel ")])]], 2)])])], 1)], 1)], 1)], 1), _c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "style-class": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "mode": "remote",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row2$user, _props$row3, _props$row4, _props$row5;
        return [props.column.field === 'format_user' ? _c('span', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.user_id ? [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$user = _props$row2.user) === null || _props$row2$user === void 0 ? void 0 : _props$row2$user.data
          }
        })] : _vm._e()], 2) : _vm._e(), props.column.field === 'status' ? _c('span', [props !== null && props !== void 0 && (_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.status ? _c('div', [(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.status) == 'initially_generated' ? _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v(" Initially Generated ")]) : _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" Finally Generated ")]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.payslip_date)))])], 1) : _c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" Generated ")])], 1)]) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.SALARY_PAYSLIP_SHOW, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              var _props$row6, _props$row6$user, _props$row6$user$data;
              return _vm.onShowPage(props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$user = _props$row6.user) === null || _props$row6$user === void 0 ? void 0 : (_props$row6$user$data = _props$row6$user.data) === null || _props$row6$user$data === void 0 ? void 0 : _props$row6$user$data.id, props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("Show")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-payslip-create-form",
      "centered": "",
      "title": "Create Payslip",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "payslipValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.payslipValidationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Assigned Employee ",
      "label-for": "user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "assigned employee",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user_id",
            "placeholder": "Assign an Employee",
            "options": _vm.userIdOption,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.userId,
            callback: function callback($$v) {
              _vm.userId = $$v;
            },
            expression: "userId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Select Month ",
      "label-for": "payslip_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "payslip_date",
      "vid": "payslip_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('date-picker', {
          attrs: {
            "type": "month",
            "placeholder": "YYYY-MM",
            "editable": false
          },
          on: {
            "change": function change($event) {
              return _vm.changePayslipDate(_vm.payslipDate);
            }
          },
          model: {
            value: _vm.payslipDate,
            callback: function callback($$v) {
              _vm.payslipDate = $$v;
            },
            expression: "payslipDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }