<template>
  <div>
    <b-card
      v-if="$permissionAbility(SALARY_PAYSLIP_CREATE, permissions)"
      title="Generate Payslip"
    >
      <!-- search input -->
      <validation-observer ref="generateAllAttendanceValidation">
        <b-form @submit.prevent="generateAllReport">
          <div>
            <b-row>
              <b-col md="3" lg="3" xs="12">
                <b-form-group label="" label-for="empId">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="User"
                    vid="empId"
                  >
                    <v-select
                      id="empId"
                      v-model="selectEmpId"
                      name="empId"
                      :options="userIdOption"
                      :reduce="(item) => item.id"
                      label="name"
                      placeholder="Employee"
                      class="mb-1 custom-font"
                    >
                      <template #option="data">
                        <UserSelect :user="data" />
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="3" lg="3" xs="12">
                <v-select
                  v-model="selectDepartmentId"
                  :options="filterDepartmentIdOption"
                  :reduce="(item) => item.id"
                  label="name"
                  placeholder="Department"
                  class="mb-1 custom-font"
                  @input="changeDepartment"
                />
              </b-col>

              <b-col md="3" lg="3" xs="12">
                <b-form-group label-for="payslip_date" class="required-label">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="payslip_date"
                    vid="payslip_date"
                  >
                    <date-picker
                      v-model="payslipDate"
                      type="month"
                      placeholder="YYYY-MM"
                      :editable="false"
                      @change="changePayslipDate(payslipDate)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="3" lg="3" xs="12">
                <div
                  class="custom-search d-flex align-items-center justify-content-end"
                >
                  <div
                    class="d-flex flex-column flex-sm-row align-items-center justify-content-around"
                  >
                    <template v-if="isReportGenerating">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="flex-shrink-0 ml-1"
                        variant="primary"
                        disabled
                      >
                        <b-spinner small />
                        Report Generating ..
                      </b-button>
                    </template>

                    <template v-else>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="flex-shrink-0 ml-1"
                        variant="primary"
                        type="submit"
                        :disabled="payslipDateInput ? false : true"
                      >
                        Generate Payslip
                      </b-button>
                    </template>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>

    <b-row>
      <b-col md="6" lg="6" xs="12">
        <b-card-actions title="Filters" action-collapse>
          <b-row>
            <b-col md="6" lg="6" xs="12" class="col">
              <b-form-group label-for="searchTerm">
                <b-form-input
                    v-model.lazy="searchTerm"
                    placeholder="Search By User Name ..."
                    type="text"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @keyup="onSearch"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12" class="col">
              <b-form-group label-for="searchTerm">
                <flat-pickr
                    v-model="filterRangeDate"
                    class="form-control"
                    placeholder="Enter Date Range"
                    :config="{
                mode: 'range',
                defaultDate: [this.filterStartDate, this.filterEndDate],
              }"
                    @input="loadItems"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-card-actions>
      </b-col>
      <b-col md="6" lg="6" xs="12">
        <b-card-actions title="Export" action-collapse>
          <b-row>

            <b-col md="6" lg="6" xs="12">
              <b-form-group label-for="payslip_date" class="required-label">

                <date-picker
                    v-model="payslipDateExcel"
                    type="month"
                    placeholder="YYYY-MM"
                    :editable="false"
                  
                />

              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <div
                  class="custom-search d-flex align-items-center justify-content-end"
              >
                <div
                    class="d-flex flex-column flex-sm-row align-items-center justify-content-around"
                >
                  <template v-if="isExcelGenerating">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="flex-shrink-0 ml-1"
                        variant="success"
                        disabled
                    >
                      <b-spinner small />
                      Excel Generating ..
                    </b-button>
                  </template>

                  <template v-else>
                    
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="flex-shrink-0 ml-1"
                          variant="success"
                          :disabled="payslipDateExcel ? false : true"
                          v-on:click="callGenerateAllExcel(payslipDateExcel)"
                      >
                        Excel
                      </b-button>
                  </template>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>

    <b-card>
      <div>
        <!-- table -->
        <vue-good-table
          style-class="vgt-table table-custom-style striped"
          :line-numbers="false"
          mode="remote"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          :rows="rows"
          :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: [
              { field: 'id', type: 'desc' },
              { field: 'created_at', type: 'desc' },
            ],
          }"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: head -->

            <span v-if="props.column.field === 'format_user'">
              <template v-if="props.row?.user_id">
                <UserCard :user="props?.row?.user?.data" />
              </template>
            </span>

            <!-- Column: Status -->
            <span v-if="props.column.field === 'status'">
              <div v-if="props?.row?.status">
                <b-badge
                  v-if="props?.row?.status == 'initially_generated'"
                  variant="light-warning"
                  class="mb-1"
                >
                  Initially Generated
                </b-badge>
                <b-badge v-else variant="light-success" class="mb-1">
                  Finally Generated
                </b-badge>
                <br />
                <span>{{ formatDate(props?.row?.payslip_date) }}</span>
              </div>
              <div v-else>
                <b-badge variant="light-success"> Generated </b-badge>
              </div>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>

                  <template
                    v-if="$permissionAbility(SALARY_PAYSLIP_SHOW, permissions)"
                  >
                    <b-dropdown-item
                      @click="
                        onShowPage(props?.row?.user?.data?.id, props.row.id)
                      "
                    >
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span>Show</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="onDelete(props.row.id)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500' ]"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <b-modal
        id="modal-payslip-create-form"
        centered
        title="Create Payslip"
        hide-footer
        no-close-on-backdrop
        @hidden="hiddenModal"
      >
        <validation-observer ref="payslipValidation">
          <b-form @submit.prevent="payslipValidationForm">
            <b-row>
              <b-col md="12" lg="12" xs="12">
                <!-- select user  -->
                <b-form-group
                  label="Assigned Employee "
                  label-for="user_id"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="assigned employee"
                    vid="user_id"
                  >
                    <v-select
                      id="user_id"
                      v-model="userId"
                      placeholder="Assign an Employee"
                      :options="userIdOption"
                      :reduce="(item) => item?.id"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  label="Select Month "
                  label-for="payslip_date"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="payslip_date"
                    vid="payslip_date"
                  >
                    <date-picker
                      v-model="payslipDate"
                      type="month"
                      placeholder="YYYY-MM"
                      :editable="false"
                      @change="changePayslipDate(payslipDate)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- loading button -->
            <template v-if="isLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="float-right"
                variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>

  <!-- Lead Modal -->
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BRow,
  BCol,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BFormDatepicker,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  max,
  mimes,
  ext,
  size,
  confirmed,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

import {
  SALARY_PAYSLIP_CREATE,
  SALARY_PAYSLIP_SHOW,
  SALARY_PAYSLIP_DELETE,
} from "@/helpers/permissionsConstant";
import { bloodGroupTypeConstants } from "@/helpers/constant/bloodGroupTypeConstant";
import { religionTypeConstants as religionValueOption } from "@/helpers/constant/religionTypeConstant";
import { maritalStatusTypeConstants as maritalStatusValueOption } from "@/helpers/constant/maritalStatusTypeConstant";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import flatPickr from "vue-flatpickr-component";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import UserCard from "@/layouts/components/UserCard.vue";
import UserSelect from "@/layouts/components/UserSelect.vue";

export default {
  name: "UsersView",
  components: {
    UserSelect,
    BCardActions,
    flatPickr,
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    UserCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BFormDatepicker,
    BLink,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      SALARY_PAYSLIP_CREATE,
      SALARY_PAYSLIP_SHOW,
      SALARY_PAYSLIP_DELETE,

      filterRangeDate: "",
      filterStartDate: "",
      filterEndDate: "",

      filterDepartmentIdOption: [],
      selectDepartmentId: "",
      filterEmpIdOption: [],
      selectEmpId: "All",
      payslipDateInput: "",
      payslipDateExcel: "",

      // pay slip
      startDate: null,
      endDate: null,
      payslipDate: null,
      payslipStartDateInput: null,
      payslipEndDateInput: null,
      isReportGenerating: false,
      isExcelGenerating: false,

      paysSlipStatusOption: [
        { title: "All" },
        { title: "Generated" },
        { title: "Not Generated" },
      ],
      paySlipStatus: { title: "All" },
      userId: "",
      userIdOption: [],

      modelType: "",
      imageSizeError: "",
      avatar: null,
      name: "",
      nid: "",
      employeeNumber: "",
      machineEmployeeId: "",
      password: "",
      password_confirmation: "",
      email: "",
      mobile: "",
      dob: "",
      salary: "",
      selectBloodGroupValue: "",
      bloodGroupTypeConstants,
      selectReligionValue: "",
      religionValueOption,
      selectMaritalStatusValue: "",
      maritalStatusValueOption,
      joinDate: "",
      selectRoleId: "",
      roleIdOption: [],
      selectGenderId: "",
      previewAvatar: null,
      genderOption: [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
      ],
      selectStatusValue: true,
      statusValueOption: [
        {
          name: "Active",
          value: true,
        },
        {
          name: "Inactive",
          value: false,
        },
      ],
      selectDesignationId: "",
      designationIdOption: [],
      selectEmployeeTypesId: "",
      employeeTypesOption: [],
      selectLeaveTypesIds: [],
      leaveTypesOption: [],
      departmentIdOption: [],
      pageLength: 10,

      columns: [
        {
          label: "Name",
          field: "format_user",
          sortable: false,
        },
        {
          label: "Department",
          field: "user.data.department.data.name",
          sortable: false,
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],

      cellStyle: {
        alignment: {
          horizontal: 'center',
          vertical: 'center'
        }
      },

      excelData: [],

      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isUserFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      user: "userModule/getUser",
      permissions: "userModule/getPermissions",
    }),

    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      const users = await this.getActiveUsers();
      const departments = await this.getDepartments();

      this.userIdOption = (users?.data?.data || []).map((item) => {
        const name = `${item?.name} (${item?.email})`;
        return {
          name,
          id: item?.id,
          departmentId: item?.department_id,
        };
      });

      this.filterDepartmentIdOption = (departments?.data?.data || []).map(
        (item) => {
          const name = item?.name;

          return {
            name,
            divisionId: item?.division_id,
            id: item?.id,
          };
        }
      );
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {

    async changeDepartment() {
      this.selectEmpId = "";
      const employees = await this.getActiveUsers();

      const employeeData = (employees?.data?.data || []).map((item) => ({
        avatar: item?.avatar,
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
        employee_number: item?.employee_number,
        id: item?.id,
        departmentId: item?.department_id,
      }));

      if (this.selectDepartmentId) {
        this.userIdOption = employeeData;

        this.userIdOption = this.userIdOption.filter(
          (item) => item?.departmentId === this.selectDepartmentId
        );
      } else {
        this.userIdOption = employeeData;
      }
    },
    async getDepartments() {
      return this.$api.get("api/departments/all");
    },
    async generateAllReport() {
      try {
        this.isLoading = true;

        await this.$api.post("/api/payroll/user/payslip-initially-generate", {
          user_id: this.selectEmpId,
          dept_id: this.selectDepartmentId,
          payslip_date: this.payslipDateInput,
        });
        this.isLoading = false;
        this.loadItems();
      } catch (error) {
        this.isLoading = false;

        if (error?.response?.data?.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }

        if (error?.response?.data?.errors) {
          this.$refs.payslipValidation.setErrors(error?.response?.data?.errors);
        }
      }
    },

    async callGenerateAllExcel(dateData){

      this.isExcelGenerating = true;

      const forExcel = true;
      await this.changePayslipDate(dateData, forExcel);

      await this.generateAllExcel().catch(error => {
        if (error?.response?.data?.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }
      });

      this.isExcelGenerating = false;


    },

    async generateAllExcel() {
      try {

        const data = await this.$api.post("/api/payroll/user/payslip-excel-report-data", {
          payslip_date: this.payslipDateExcel,
        });

        console.log(data?.data?.data);

        window.location.href = data?.data?.data;
        this.excelData = [];

      } catch (error) {

        if (error?.response?.data?.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }

        if (error?.response?.data?.errors) {
          this.$refs.payslipValidation.setErrors(error?.response?.data?.errors);
        }
      }
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/payroll/payslip/${id}`);

            await this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Payslip Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    handleDateRangeChange() {
      if (this.filterRangeDate && this.filterRangeDate !== "") {
        const dates = this.filterRangeDate.split(" to ");

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error("Invalid date range format");
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    async getActiveUsers() {
      return this.$api.get("api/users/active-all");
    },
    showModal() {
      this.$bvModal.show("modal-payslip-create-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-payslip-create-form");
      this.resetModal();
    },
    resetModal() {
      this.userId = "";
      this.payslipDate = null;
      this.payslipDateInput = null;
    },
    changePayslipDate(dateData, forExcel) {
      const payslipDate = new Date(dateData);

      const year = payslipDate.getFullYear();
      const month = String(payslipDate.getMonth() + 1).padStart(2, "0");
      const day = String(payslipDate.getDate()).padStart(2, "0");

      const formattedStartDate = `${year}-${month}-${day}`;

      if (forExcel){
        this.payslipDateExcel = formattedStartDate;
      }else{
        this.payslipDateInput = formattedStartDate;
      }

    },
    changeStartDate() {
      if (this.startDate) {
        const startDate = new Date(this.startDate);

        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");

        const formattedStartDate = `${year}-${month}-${day}`;

        this.payslipStartDateInput = formattedStartDate;
      } else {
        this.payslipStartDateInput = null;
      }

      this.loadItems();
    },
    changeEndDate() {
      if (this.endDate) {
        const endDate = new Date(this.endDate);

        const year = endDate.getFullYear();
        const month = endDate.getMonth() + 1;
        const lastDay = new Date(year, month, 0);

        const formattedLastDate = `${year}-${String(month).padStart(
          2,
          "0"
        )}-${String(lastDay.getDate()).padStart(2, "0")}`;

        this.payslipEndDateInput = formattedLastDate;
      } else {
        this.payslipEndDateInput = null;
      }

      this.loadItems();
    },

    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM YYYY");
      }
      return "Not Set";
    },
    filterPayslip() {
      this.loadItems();
    },
    async onShowPage(userId, id) {
      try {
        this.$router.push({
          name: "admin-salary-payslip-details-dev",
          params: { userId, id },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    onContextPurchaseDate(ctx) {
      this.purchaseDate = ctx.selectedYMD;
    },
    roleName(rowObj) {
      return rowObj?.roles?.data[0]?.name;
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async getPayslips(params) {
      return await this.$api.get(
        "api/payroll/payslip?include=user.department,user.designation",
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
            startDate: params.startDate,
            endDate: params.endDate,
          },
        }
      );
    },

    async loadItems() {
      try {
        this.handleDateRangeChange();

        const [payslips] = await Promise.all([
          this.getPayslips({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            startDate: this.filterStartDate,
            endDate: this.filterEndDate,
          }),
        ]);

        const data = payslips?.data?.data;
        const meta = payslips?.data?.meta;

        this.totalRecords = meta?.pagination?.total;

        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async payslipValidationForm() {
      this.$refs.payslipValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.payslipValidation.reset();
            this.isLoading = true;

            await this.$api.post(
              "/api/payroll/user/payslip-initially-generate",
              {
                user_id: this.userId,
                payslip_date: this.payslipDateInput,
              }
            );
            this.isLoading = false;
            this.hiddenModal();
            this.loadItems();
          } catch (error) {
            this.isLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.payslipValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.custom-font {
  font-size: 13px !important;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.required-label label::after {
  content: " *";
  color: red;
}
.mx-datepicker {
  width: 100%;
}

.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
  }
}
.custom-font {
  font-size: 13px !important;
}
.custom-font-validation {
  font-size: 11px;
}
.cell-with-description div {
  margin: 2px 0px;
}
</style>
